import React, { useState, useEffect } from "react";
import { apiUrl } from "../Pages/Config";
import "../Styles/Pricing.css"; // Assuming you put the CSS in this file
import {
   setPrice,
   setLoading } from "../Pages/Store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PricingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [loading, setLoadings] = useState(true);
  const [productDetails, setProductDetails] = useState(null);
  const accessToken = localStorage.getItem('accessToken');
  
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      dispatch(setLoading(true))
      try {
          const productResponse = await fetch(
            `${apiUrl}fetchProductDetails/FrothTestops`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (productResponse.ok) {
            const productData = await productResponse.json();
            setProductDetails(productData);
          } else {
            toast.warn("Failed to fetch product detials", {
              position: "top-right",
              autoClose: 2000,
            });
          }
          dispatch(setLoading(false))
      } catch (error) {
        console.error("Error during login or fetching product details:", error);
       
      } finally {
        setLoadings(false);
      }
    };

    fetchProductDetails();
  }, []);

  const handleBuy = (value) => {
    dispatch(setLoading(true))
    localStorage.setItem('BuyPlan',true)
    dispatch(setPrice({ ...value, isToggled }))
    navigate('/SignUp')   
    dispatch(setLoading(false))
  }

  const handleContact = () => {    
    localStorage.setItem("plan","Enterprise Plan")
    window.open("/ContactUs", "_blank");
  };

  return (
    <div
      className="container"
      id="pricing-page"
      style={{ marginLeft: ".5rem", marginTop: "1rem" }}
    >
      <div className="text-center" id="header-part">
        <h1 className="plan-title">Pricing Plan</h1>
        <span class="hr-line mb-3"></span>
        <div class="form-check form-switch" style={{paddingLeft: '0rem',display: 'flex',gap:'5%'}}>
            <label class="form-check-label ms-2" for="billing-toggle" style={{color: "#7A7A7A",fontSize: '18px',fontWeight: "400"}}>Monthly</label>
            <label class="switch">
                <input type="checkbox" id="billing-toggle" checked={isToggled}
            onChange={handleToggle} />
                <span class="slider round"></span>
            </label>
            <label class="form-check-label" for="flexSwitchCheckDefault" style={{color: "#7A7A7A",fontSize: '18px',fontWeight: "400"}}>Yearly</label>
        </div>
        <div style={{color: isToggled ? '#7A7A7A' : 'rgb(58 71 255)'}} id="discount-msg">Pay annually and get 20% discount</div>
      </div>
      {!loading && (
        <div
          className="row text-center"
          style={{ display: "flex",flexDirection:'row',gap:'4%' }}
        >
          <div className="col">
            <div
              className="card mb-4 rounded-3 shadow-sm"
              style={{ margin: "10px", padding: "0" }}
            >
              <div
                className="card-header py-3"
                style={{ backgroundColor: "#54595F" }}
              >
                <h4
                  className="my-0 fw-normal text-white text-center"
                  id="enterprise-plan-title"
                >
                  ESSENTIAL PLAN
                </h4>
                <div className="corner-ribbon corner-ribbon-popular">
                  <span>POPULAR</span>
                </div>
              </div>
              <div className="card-body text-center">
              <h1 class="pricing-card-title">
                        <span class="price-essential"><sup id="dollar">$</sup>US {isToggled ? productDetails?.Essential_Plan?.yearly:productDetails?.Essential_Plan?.monthly}</span>
                        <sup id="month" class="billing-period text-muted fw-light"> Per Month</sup>
                    </h1> 
                <ul
                  className="list-unstyled mt-3 mb-4"
                  id="enterprise-plan-description"
                >
                  {productDetails?.Essential_Plan?.description
                    .split("|")
                    .map((item, index) => (
                      <li key={index}>
                        {item}
                        {index <
                          productDetails?.Essential_Plan?.description.split(
                            "|"
                          ).length -
                            1 && <hr id="list-line" />}
                      </li>
                    ))}
                </ul>
                <button
                  type="button"
                  id="buy-now"
                  className="btn"
                  onClick={()=>handleBuy(productDetails?.Essential_Plan)}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card mb-4 rounded-3 shadow-sm"
              style={{ margin: "10px", padding: "0" }}
            >
              <div
                className="card-header py-3"
                style={{ backgroundColor: "#54595F" }}
              >
                <h4
                  className="my-0 fw-normal text-white text-center"
                  id="enterprise-plan-title"
                >
                  ULTIMATE PLAN
                </h4>
                <div className="corner-ribbon corner-ribbon-business">
                  <span>BUSINESS</span>
                </div>
              </div>
              <div className="card-body text-center">
                <h1 class="pricing-card-title">
                        <span class="price-essential"><sup id="dollar">$</sup>US {isToggled ? productDetails?.Ultimate_Plan?.yearly:productDetails?.Ultimate_Plan?.monthly}</span>
                        <sup id="month" class="billing-period text-muted fw-light"> Per Month</sup>
                    </h1> 
                <ul
                  className="list-unstyled mt-3 mb-4"
                  id="enterprise-plan-description"
                >
                  {productDetails?.Ultimate_Plan?.description
                    .split("|")
                    .map((item, index) => (
                      <li key={index}>
                        {item}
                        {index <
                          productDetails?.Ultimate_Plan?.description.split(
                            "|"
                          ).length -
                            1 && <hr id="list-line" />}
                      </li>
                    ))}
                </ul>
                <button
                  type="button"
                  id="buy-now"
                  className="btn"
                  onClick={()=>handleBuy(productDetails?.Ultimate_Plan)}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card mb-4 rounded-3 shadow-sm"
              style={{ margin: "10px", padding: "0" }}
            >
              <div
                className="card-header py-3"
                style={{ backgroundColor: "#54595F" }}
              >
                <h4
                  className="my-0 fw-normal text-white text-center"
                  id="enterprise-plan-title"
                >
                  ENTERPRISE PLAN
                </h4>
                <div className="corner-ribbon corner-ribbon-enterprise">
                  <span>PROFESSIONAL</span>
                </div>
              </div>
              <div className="card-body text-center">
                <ul
                  className="list-unstyled mt-3 mb-4"
                  id="enterprise-plan-description"
                >
                  {productDetails?.Enterprise_Plan?.description
                    .split("|")
                    .map((item, index) => (
                      <li key={index}>
                        {item}
                        {index <
                          productDetails?.Enterprise_Plan?.description.split(
                            "|"
                          ).length -
                            1 && <hr id="list-line" />}
                      </li>
                    ))}
                </ul>
                <button
                  type="button"
                  id="buy-now"
                  className="btn"
                  onClick={handleContact}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingPage;
