import React from 'react'
import img from "../Assets/successpage.jpg"

const SuccessPage = () => {

    const variable = window.location.search?.split("?")
    if(variable){
    const res =variable[1]?.split("&")
    const username =res[0]?.split("=")
    localStorage.setItem('username',username[1])
    }
    
    return (
        <div
            id="addTicketPopup"
            style={{
                display: "block",
                position: "fixed",
                zIndex: 1050, // Make sure this is higher than the header
            }}
        >
            <div
                className="card popup d-flex align-items-center text-center"
                style={{ margin: "10px", padding: "1rem", width: '30%' }}
            >
                <img src={img} alt="img" style={{ height: "12rem", width: '12rem' }} />
                <h5>Thank you for the payment</h5>
                <span>Your company and an account has been created. Click <a href='PasswordSetup' >here</a> to reach our Support team for further assistance </span>
            </div>
        </div>
    )
}

export default SuccessPage